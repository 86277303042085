<template>
    <div class="privacypolicy">
        <div class="wrapContentExt">
            <div>
                <img class="logo" alt="lingodeer-website-logo" :src="logo">
            </div>
            <div class="fr-view">
                <h1 style="margin:0;margin-bottom:35px;text-align:center;font-size: 27px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-family:宋体;color:#0A0A0A;padding:0;">鹿老师说专项练习用户协议</span>
                </h1>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">欢迎您使用</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">软件及相关服务！</span></p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">为了更好地为您提供服务，请您仔细阅读《鹿老师说专项练习用户服务协议》（以下简称</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">本协议</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">）。在您开始使用</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">软件及相关服务之前，请您务必认真阅读并充分理解本协议，特别是涉及免除或者限制责任的条款、权利许可和信息使用的条款、同意开通和使用特殊单项服务的条款、法律适用和争议解决条款等。其中，免除或者限制责任条款等重要内容将以加粗形式提示您注意，您应重点阅读。如您未满</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">18</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">周岁，请您在法定监护人陪同下仔细阅读并充分理解本协议，并征得法定监护人的同意后下载使用本软件。</span>
                </p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">除非您完全接受本协议的全部内容，否则您无权下载、安装、注册、登录、使用（以下统称</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">使用</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">）</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">软件，或者通过任何方式使用</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">服务，或者获得</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">软件提供的任何服务</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">&nbsp;</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">（本协议中统称</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">使用</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">）。若您使用</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">软件及相关服务，则视为您已充分理解本协议并承诺作为本协议的一方当事人接受协议的约束。</span>
                </p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">如对本协议内容有任何疑问、意见或建议，您可通过我方平台的客服与我们联系。</span>
                </p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">1.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">适用范围</span></p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">1.1.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">本协议是您与北京易言科技有限公司（以下简称</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">公司</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">或</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">我们</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">）之间就您下载、安装、注册、登录、使用</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">软件，并获得</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">软件提供的相关服务所订立的协议。就本协议项下涉及的某些服务，可能会由公司的关联公司、控制公司向您提供，您知晓并同意接受上述服务内容，即视为接受双方之间的相关权利义务关系亦受本协议约束。</span>
                </p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">1.2.“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">用户</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">指所有直接或间接获取和使用</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">软件及相关服务的使用者，包括自然人、法人和其他组织等。在本协议中称为</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">用户</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">或称</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">您</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">。</span></p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">1.3.“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">指由公司合法拥有并运营的、标注名称为</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">的客户端应用程序以及</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">鹿老师说外语</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">（</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;"><a
                        href="http://www.lingodeer.cn/" target="_top"><span
                        style="color:#7D483D;padding:0px;text-decoration:none;">www.lingodeer.cn</span></a></span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">）相关网站。公司有权对应用程序及网站名称单方变更（包括但不限于更名、新增等）。</span>
                </p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">1.4.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">本协议内容同时包括公司已经发布及后续可能不断发布的关于</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">软件及相关服务的相关协议、规则等内容。前述内容一经正式发布，并以适当的方式送达用户（网站公布、系统通知等），即为本协议不可分割的组成部分，您应同样遵守。若您不同意公司后续发布的该等协议内容，您应停止使用</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">&nbsp;“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">软件及相关服务。</span></p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">2.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">使用</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">软件及相关服务</span></p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">2.1.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">您使用</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">软件及相关服务，可以通过预装、公司已授权的第三方下载等方式获取</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">客户端应用程序或访问鹿老师说专项练习相关网站。若您并非从公司或经公司授权的第三方获取本软件的，公司无法保证未经授权版本的</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">软件能够正常使用，您因此遭受的损失与公司无关。</span>
                </p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">2.2.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">公司可能为不同的终端设备开发了不同的应用程序软件版本，您应当根据实际设备状况获取、下载、安装合适的版本。</span>
                </p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">2.3.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">您可根据自行需要使用</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">软件及相关服务或更新</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">版本，如您不再需要使用</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">软件及相关服务可自行卸载。</span></p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">2.4.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">为更好的提升用户体验及服务，公司将不定期提供软件更新或改变（包括但不限于软件修改、升级、功能强化、开发新服务、软件替换等）。为保证</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">软件及相关服务安全、提升用户服务，本软件及相关服务更新或部分服务内容更新后，在可能的情况下，公司将以包括但不限于系统提示、公告、站内信等方式提示用户，用户有权选择接受更新版本或服务，如用户不接受，部分功能将受到限制或不能继续使用。</span>
                </p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">2.5.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">除非得到公司事先书面授权，您不得以任何形式对</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">软件及相关服务进行包括但不限于改编、复制、传播、垂直搜索、镜像或交易等未经授权的访问或使用。</span>
                </p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">2.6.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">您理解，您使用</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">软件及相关服务需自行准备与软件及相关服务有关的终端设备（如电脑、手机等），一旦您在其终端设备中打开</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">软件或访问</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">相关网站，即视为您使用</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">软件及相关服务。为充分实现</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">的全部功能，您可能需要将其终端设备联网，您理解由您承担所需要的费用（如流量费、上网费等），相关资费信息请自行向运营商了解。</span>
                </p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">2.7.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">公司许可您个人不可转让地、非独占地和非商业地合法使用</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">软件及相关服务的权利。本协议未明示授权的其他一切权利仍由公司保留，您在行使该些权利时须另行获得公司的书面许可，同时公司如未行使前述任何权利，并不构成对该权利的放弃。</span>
                </p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">2.8.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">您无需注册即可开始使用</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">软件及相关服务。同时，您也理解，为使您更好地使用</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">软件及相关服务，保障您的帐号安全，某些功能和</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">/</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">或某些单项服务项目，如跟帖评论服务等，要求您按照国家相关法律法规的规定，提供真实的身份信息实名注册并登录后方可使用。</span>
                </p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">2.9.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">如您发现本软件或网站内存在任何侵犯您权利的内容，请立即通过网站公示的途径通知公司，提供您有相关权利的初步证据，公司将会根据当地法律和行政规定及时处理您的投诉。</span>
                </p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">3.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">关于</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">帐号</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">”</span>
                </p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">3.1.“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">为用户提供了注册通道，用户有权选择合法的字符组合作为自己的帐号，并自行设置符合安全要求的密码。用户设置的帐号、密码是用户用以登录并以注册用户身份使用</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">软件及相关服务的凭证。</span></p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">3.2.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">您理解并承诺，您所设置的帐号不得违反国家法律法规及公司的相关规则，您的帐号名称、头像和简介等注册信息及其他个人信息中不得出现违法和不良信息，未经他人许可不得用他人名义（包括但不限于冒用他人姓名、名称、字号、头像等足以让人引起混淆的方式）开设帐号，不得恶意注册</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">帐号（包括但不限于频繁注册、批量注册帐号等行为）。您在帐号注册及使用过程中需遵守相关法律法规，不得实施任何侵害国家利益、损害其他公民合法权益，有害社会道德风尚的行为。公司有权对您提交的注册信息进行审核。</span>
                </p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">3.3.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">您在</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">中的注册帐号所有权及有关权益均归公司所有，您完成注册手续后仅享有该帐号的使用权。您的帐号仅限于您本人使用，未经公司书面同意，禁止以任何形式赠与、借用、出租、转让、售卖或以其他方式许可他人使用该帐号。如果公司发现或者有合理理由认为使用者并非帐号初始注册人，公司有权在未通知您的情况下，暂停或终止向该注册帐号提供服务，并有权注销该帐号，而无需向注册该帐号的用户承担法律责任。</span>
                </p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">3.4.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">您有责任维护个人帐号、密码的安全性与保密性，并对您以注册帐号名义所从事的活动承担全部法律责任，包括但不限于您在</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">上进行的任何数据修改、言论发表、款项支付等操作行为。您应高度重视对帐号与密码的保密，在任何情况下不向他人透露帐号及密码。若发现他人未经许可使用您的帐号或发生其他任何安全漏洞问题时，您应当立即通知公司。</span>
                </p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">3.5.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">您的帐号在丢失或遗忘密码后，可遵照公司的申诉途径及时申诉请求找回帐号或密码。您理解并认可，公司的密码找回机制仅需要识别申诉单上所填资料与系统记录资料具有一致性，而无法识别申诉人是否系真正帐号有权使用者。公司特别提醒您应妥善保管您的帐号和密码。当您使用完毕后，应安全退出。因您保管不当可能导致遭受盗号或密码丢失，责任由您自行承担。</span>
                </p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">3.6.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">在注册、使用和管理帐号时，您应保证注册帐号时填写的身份信息的真实性，请您在注册、管理帐号时使用真实、准确、合法、有效的相关身份证明材料及必要信息（包括您的姓名及电子邮件地址、联系电话、联系地址等）。依照国家相关法律法规的规定，为使用本软件及服务，您需要填写真实的身份信息，请您按照相关法律规定完成实名认证，并注意及时更新上述相关信息。若您提交的材料或提供的信息不准确、不真实、不合法或者公司有理由怀疑为错误、不实或不合法的资料，则公司有权拒绝为您提供相关服务或您可能无法使用</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">软件及相关服务或在使用过程中部分功能受到限制。</span>
                </p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">3.7.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">除自行注册</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">帐号外，用户也可授权使用其合法拥有的包括但不限于公司和</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">/</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">或其关联公司、控制公司其他软件用户帐号，以及实名注册的第三方软件或平台用户帐号注册并登录使用</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">软件及相关服务，但第三方软件或平台对此有限制或禁止的除外。当用户以前述已有帐号登录使用的，应保证相应帐号已进行实名注册登记，同样适用本协议中的相关约定。</span>
                </p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">3.8.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">因您自身原因或其他不可抗因素而导致帐号被盗、丢失，均由您本人承担责任，公司不承担任何责任。</span>
                </p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">3.9.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">您理解，为了充分使用帐号资源，如用户注册的免费网络服务的帐号在任何连续</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">180</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">日内未实际使用，或者用户注册的收费网络服务的帐号在其订购的收费网络服务的服务期满之后连续</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">180</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">日内未实际使用，则</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">有权删除该帐号并停止为该用户提供相关的网络服务。</span>
                </p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">3.10.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">帐号注销。在需要终止使用</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">帐号服务时，符合以下条件的，您可以申请注销您的</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">帐号：</span></p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">3.10.1.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">您仅能申请注销您本人的帐号，并依照</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">的流程进行注销；</span></p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">3.10.2.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">您仍应对您在注销帐号前且使用</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">服务期间的行为承担相应责任；</span></p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">3.10.3.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">注销完成后，帐号记录、功能、数据等将无法恢复或提供。</span>
                </p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">3.11.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">如您需要注销您的</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">帐号，请通过我方平台的客服进行操作。</span></p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">4.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">用户个人信息保护</span></p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">4.1.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">我们非常重视用户信息的保护，保护用户个人信息是公司的基本原则之一。公司与用户一同致力于用户个人信息（即能够独立或与其他信息结合后识别用户身份的信息）的保护。</span>
                </p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">4.2.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">您同意并理解，您一旦选择使用</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">软件及相关服务，即意味着同意公司按照《鹿老师说专项练习隐私政策》（以下简称</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">本隐私政策</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">）收集、使用（含商业合作使用）、储存和分享您的相关信息。公司将运用与</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">软件及相关服务相匹配的安全技术及其他安全措施，并建立完善的管理制度来保护您的个人信息。</span>
                </p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">4.3.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">在使用</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">软件及相关服务过程中，公司可能会收集和使用您的相关信息，特定功能的实现可能需要额外收集和使用您的其他相关信息，您依法具有浏览、修改、删除相关个人信息以及撤回同意的权利。您可随时浏览、修改自己提交的个人身份信息，您理解并同意出于安全性和身份识别（如账号或密码找回申诉服务等）的考虑，您可能无法修改注册时提供的初始注册信息及其他验证信息。</span>
                </p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">4.4.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">未经您本人允许，我们不会向任何第三方共享、转让、公开披露您的个人信息，下列情形除外：</span>
                </p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">（</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">1</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">）</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">&nbsp;</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">事先获得您的明确授权同意；</span></p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">（</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">2</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">）与国家安全、国防安全、公共安全、公共卫生、公共利益直接相关的；</span>
                </p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">（</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">3</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">）</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">&nbsp;</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">根据适用的法律法规、法规程序的要求、强制性的行政司法要求所必须的情况下进行披露或提供，或与犯罪侦查、起诉、审判和判决执行等直接相关的；我们会依据所要求的个人信息类型和披露方式公开披露您的个人信息。在符合法律法规的前提下，当我们收到上述披露信息的请求时，我们会要求必须出具与之相应的法律文件，如传票或调查函。我们将对所有的请求都进行审慎的审查，以确保其具备合法依据，且仅限于行政、司法部门因特定调查目的有合法权利获取的数据；</span>
                </p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">（</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">4</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">）</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">&nbsp;</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">在法律法规允许的范围内，为维护</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">其他用户、公司及其关联公司、控制公司的生命、财产等合法权益或维权产品或服务的安全稳定运行所必需的，例如查找、预防、处理欺诈等违法活动和减少信用风险等；不过这并不包括违反本隐私政策中所做的承诺而为获利目的对外公开或提供个人信息；</span>
                </p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">（</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">5</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">）</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">&nbsp;</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">公司为维护合法权益而向用户提起诉讼或仲裁；</span>
                </p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">（</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">6</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">）</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">&nbsp;</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">在涉及合并、分立、收购、资产转让或类似的交易时，如涉及到个人信息转让，公司会要求新的持有您的个人信息的公司、组织继续受本隐私政策的约束，否则，公司有权要求该公司、组织重新取得您的授权同意；</span>
                </p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">（</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">7</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">）</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">&nbsp;</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">从合法公开披露的信息中涉及的个人信息的，如合法的新闻报道、政府信息公开等渠道；</span>
                </p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">（</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">8</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">）</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">&nbsp;</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">为学术研究目的，或为学研究机构，出于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的；</span>
                </p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">（</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">9</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">）法律法规规定的其他情形。</span></p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">4.5.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">请您注意勿在使用</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">软件及相关服务中透露自己的各类财产账户、银行卡、信用卡、第三方支付账户及对应密码等重要资料，否则由此带来的任何损失由您自行承担。</span>
                </p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">5.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">用户行为规范</span></p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">5.1.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">用户行为要求</span></p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">您应当对您使用本产品及相关服务的行为负责，除非法律允许或者经公司事先书面许可，您使用</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">软件及相关服务不得具有下列行为：</span></p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">5.1.1.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">使用未经公司授权或许可的任何插件、外挂、系统或第三方工具对</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">软件及相关服务的正常运行进行干扰、破坏、修改或施加其他影响。</span>
                </p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">5.1.2.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">利用或针对</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">软件及相关服务进行任何危害计算机网络安全的行为，包括但不限于：</span>
                </p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;"><a
                            href="http://5.1.2.1/" target="_blank"><span
                            style="color:#7D483D;padding:0px;text-decoration:none;">5.1.2.1</span></a><span
                            style="padding:0px;">.</span></span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">非法侵入他人网络、干扰他人网络正常功能、窃取网络数据等危害网络安全的活动；</span>
                </p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;"><a
                            href="http://5.1.2.2/" target="_blank"><span
                            style="color:#7D483D;padding:0px;text-decoration:none;">5.1.2.2</span></a><span
                            style="padding:0px;">.</span></span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">提供专门用于从事侵入网络、干扰网络正常功能及防护措施、窃取网络数据等危害网络安全活动的程序、工具；</span>
                </p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;"><a
                            href="http://5.1.2.3/" target="_blank"><span
                            style="color:#7D483D;padding:0px;text-decoration:none;">5.1.2.3</span></a><span
                            style="padding:0px;">.</span></span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">明知他人从事危害网络安全的活动的，为其提供技术支持、广告推广、支付结算等帮助</span>
                </p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;"><a
                            href="http://5.1.2.4/" target="_blank"><span
                            style="color:#7D483D;padding:0px;text-decoration:none;">5.1.2.4</span></a><span
                            style="padding:0px;">.</span></span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">使用未经许可的数据或进入未经许可的服务器</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">/</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">帐号；</span></p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;"><a
                            href="http://5.1.2.5/" target="_blank"><span
                            style="color:#7D483D;padding:0px;text-decoration:none;">5.1.2.5</span></a><span
                            style="padding:0px;">.</span></span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">未经允许进入公众计算机网络或者他人计算机系统并删除、修改、增加存储信息；</span>
                </p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;"><a
                            href="http://5.1.2.6/" target="_blank"><span
                            style="color:#7D483D;padding:0px;text-decoration:none;">5.1.2.6</span></a><span
                            style="padding:0px;">.</span></span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">未经许可，企图探查、扫描、测试</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">系统或网络的弱点或其它实施破坏网络安全的行为；</span>
                </p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;"><a
                            href="http://5.1.2.7/" target="_blank"><span
                            style="color:#7D483D;padding:0px;text-decoration:none;">5.1.2.7</span></a><span
                            style="padding:0px;">.</span></span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">企图干涉、破坏</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">系统或网站的正常运行，故意传播恶意程序或病毒以及其他破坏干扰正常网络信息服务的行为；</span>
                </p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;"><a
                            href="http://5.1.2.8/" target="_blank"><span
                            style="color:#7D483D;padding:0px;text-decoration:none;">5.1.2.8</span></a><span
                            style="padding:0px;">.</span></span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">伪造</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">TCP/IP</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">数据包名称或部分名称。</span></p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">5.1.3.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">对</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">软件进行反向工程、反向汇编、编译或者以其他方式尝试发现本软件的源代码。</span>
                </p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">5.1.4.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">恶意注册</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">帐号，包括但不限于频繁、批量注册帐号。</span></p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">5.1.5.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">违反法律法规、本协议、公司的相关规则及侵犯他人合法权益的其他行为。</span>
                </p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">5.1.6.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">在任何情况下，如果公司有理由认为您的任何行为违反或可能违反上述约定的，公司可独立进行判断并处理，且在任何时候有权在进行任何事先通知的情况下终止向用户提供服务，并追究相关责任。</span>
                </p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">5.2.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">信息内容规范</span></p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">5.2.1.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">用户按规定完成实名认证后，可以以注册帐号或</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">合作平台帐号登录</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">发布内容、浏览内容、进行评论等。</span></p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">5.2.2.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">用户评论、发布、传播的内容应自觉遵守宪法法律、法规、遵守公共秩序，尊重社会公德、社会主义制度、国家利益、公民合法权益、道德风尚和信息真实性等要求。用户不得制作、复制、发布、传播法律、行政法规禁止的下列信息内容：</span>
                </p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;"><a
                            href="http://5.2.2.1/" target="_blank"><span
                            style="color:#7D483D;padding:0px;text-decoration:none;">5.2.2.1</span></a><span
                            style="padding:0px;">.</span></span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">反对宪法确定的基本原则的；</span></p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;"><a
                            href="http://5.2.2.2/" target="_blank"><span
                            style="color:#7D483D;padding:0px;text-decoration:none;">5.2.2.2</span></a><span
                            style="padding:0px;">.</span></span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">危害国家安全，泄露国家秘密的；</span></p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;"><a
                            href="http://5.2.2.3/" target="_blank"><span
                            style="color:#7D483D;padding:0px;text-decoration:none;">5.2.2.3</span></a><span
                            style="padding:0px;">.</span></span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">颠覆国家政权，推翻社会主义制度、煽动分裂国家、破坏国家统一的；</span>
                </p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;"><a
                            href="http://5.2.2.4/" target="_blank"><span
                            style="color:#7D483D;padding:0px;text-decoration:none;">5.2.2.4</span></a><span
                            style="padding:0px;">.</span></span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">损害国家荣誉和利益的；</span></p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;"><a
                            href="http://5.2.2.5/" target="_blank"><span
                            style="color:#7D483D;padding:0px;text-decoration:none;">5.2.2.5</span></a><span
                            style="padding:0px;">.</span></span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">宣扬恐怖主义、极端主义的；</span></p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;"><a
                            href="http://5.2.2.6/" target="_blank"><span
                            style="color:#7D483D;padding:0px;text-decoration:none;">5.2.2.6</span></a><span
                            style="padding:0px;">.</span></span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">宣扬民族仇恨、民族歧视，破坏民族团结的；</span></p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;"><a
                            href="http://5.2.2.7/" target="_blank"><span
                            style="color:#7D483D;padding:0px;text-decoration:none;">5.2.2.7</span></a><span
                            style="padding:0px;">.</span></span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">煽动地域歧视、地域仇恨的；</span></p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;"><a
                            href="http://5.2.2.8/" target="_blank"><span
                            style="color:#7D483D;padding:0px;text-decoration:none;">5.2.2.8</span></a><span
                            style="padding:0px;">.</span></span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">破坏国家宗教政策，宣扬邪教和迷信的；</span></p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;"><a
                            href="http://5.2.2.9/" target="_blank"><span
                            style="color:#7D483D;padding:0px;text-decoration:none;">5.2.2.9</span></a><span
                            style="padding:0px;">.</span></span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">编造、散布谣言、虚假信息，扰乱经济秩序和社会秩序、破坏社会稳定的；</span>
                </p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;"><a
                            href="http://5.2.2.10/" target="_blank"><span
                            style="color:#7D483D;padding:0px;text-decoration:none;">5.2.2.10</span></a><span
                            style="padding:0px;">.</span></span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">散布、传播暴力、淫秽、色情、赌博、凶杀、恐怖或者教唆犯罪的；</span>
                </p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;"><a
                            href="http://5.2.2.11/" target="_blank"><span
                            style="color:#7D483D;padding:0px;text-decoration:none;">5.2.2.11</span></a><span
                            style="padding:0px;">.</span></span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">侵害未成年人合法权益或者损害未成年人身心健康的；</span>
                </p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;"><a
                            href="http://5.2.2.12/" target="_blank"><span
                            style="color:#7D483D;padding:0px;text-decoration:none;">5.2.2.12</span></a><span
                            style="padding:0px;">.</span></span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">未获他人允许，偷拍、偷录他人，侵害他人合法权利的；</span>
                </p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;"><a
                            href="http://5.2.2.13/" target="_blank"><span
                            style="color:#7D483D;padding:0px;text-decoration:none;">5.2.2.13</span></a><span
                            style="padding:0px;">.</span></span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">包含恐怖、暴力血腥、高危险性、危害表演者自身或他人身心健康内容的；</span>
                </p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;"><a
                            href="http://5.2.2.14/" target="_blank"><span
                            style="color:#7D483D;padding:0px;text-decoration:none;">5.2.2.14</span></a><span
                            style="padding:0px;">.</span></span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">危害网络安全、利用网络从事危害国家安全、荣誉和利益的；</span>
                </p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;"><a
                            href="http://5.2.2.15/" target="_blank"><span
                            style="color:#7D483D;padding:0px;text-decoration:none;">5.2.2.15</span></a><span
                            style="padding:0px;">.</span></span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">侮辱或者诽谤他人，侵害他人合法权益的；</span></p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;"><a
                            href="http://5.2.2.16/" target="_blank"><span
                            style="color:#7D483D;padding:0px;text-decoration:none;">5.2.2.16</span></a><span
                            style="padding:0px;">.</span></span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">对他人进行暴力恐吓、威胁，实施人肉搜索的；</span>
                </p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;"><a
                            href="http://5.2.2.17/" target="_blank"><span
                            style="color:#7D483D;padding:0px;text-decoration:none;">5.2.2.17</span></a><span
                            style="padding:0px;">.</span></span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">涉及他人隐私、个人信息或资料的；</span></p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;"><a
                            href="http://5.2.2.18/" target="_blank"><span
                            style="color:#7D483D;padding:0px;text-decoration:none;">5.2.2.18</span></a><span
                            style="padding:0px;">.</span></span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">散布污言秽语，损害社会公序良俗的；</span></p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;"><a
                            href="http://5.2.2.19/" target="_blank"><span
                            style="color:#7D483D;padding:0px;text-decoration:none;">5.2.2.19</span></a><span
                            style="padding:0px;">.</span></span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">侵犯他人隐私权、名誉权、肖像权、知识产权等合法权益内容的；</span>
                </p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;"><a
                            href="http://5.2.2.20/" target="_blank"><span
                            style="color:#7D483D;padding:0px;text-decoration:none;">5.2.2.20</span></a><span
                            style="padding:0px;">.</span></span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">散布商业广告，或类似的商业招揽信息、过度营销信息及垃圾信息；</span>
                </p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;"><a
                            href="http://5.2.2.21/" target="_blank"><span
                            style="color:#7D483D;padding:0px;text-decoration:none;">5.2.2.21</span></a><span
                            style="padding:0px;">.</span></span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">使用本网站常用语言文字以外的其他语言文字评论的；</span>
                </p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;"><a
                            href="http://5.2.2.22/" target="_blank"><span
                            style="color:#7D483D;padding:0px;text-decoration:none;">5.2.2.22</span></a><span
                            style="padding:0px;">.</span></span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">与所评论的信息毫无关系的；</span></p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;"><a
                            href="http://5.2.2.23/" target="_blank"><span
                            style="color:#7D483D;padding:0px;text-decoration:none;">5.2.2.23</span></a><span
                            style="padding:0px;">.</span></span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">所发表的信息毫无意义的，或刻意使用字符组合以逃避技术审核的；</span>
                </p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;"><a
                            href="http://5.2.2.24/" target="_blank"><span
                            style="color:#7D483D;padding:0px;text-decoration:none;">5.2.2.24</span></a><span
                            style="padding:0px;">.</span></span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">其他违反法律法规、政策及公序良俗、干扰</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">正常运营或侵犯其他用户或第三方合法权益内容的其他信息。</span>
                </p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">6.“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">数据使用规范</span></p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">6.1.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">未经公司书面许可，用户不得自行或授权、允许、协助任何第三人对本协议</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">软件及相关服务中信息内容进行如下行为：</span></p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">6.1.1.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">复制、读取、采用</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">软件及相关服务的信息内容，用于包括但不限于宣传、增加阅读量、浏览量等商业用途；</span>
                </p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">6.1.2.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">擅自编辑、整理、编排</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">软件及相关服务的信息内容后在</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">软件及相关服务的源页面以外的渠道进行展示；</span>
                </p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">6.1.3.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">采用包括但不限于特殊标识、特殊代码等任何形式的识别方法，自行或协助第三人对</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">软件及相关服务的的信息或内容产生流量、阅读量引导、转移、劫持等不利影响；</span>
                </p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">6.1.4.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">其他非法获取</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">软件及相关服务的信息内容的行为。</span></p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">6.2.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">经公司书面许可后，用户对</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">软件及相关服务的信息和内容的分享、转发等行为，还应符合以下规范：</span>
                </p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">6.2.1.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">对抓取、统计、获得的相关搜索热词、命中率、分类、搜索量、点击率、阅读量等相关数据，未经公司事先书面同意，不得将上述数据以任何方式公示、提供、泄露给任何第三人；</span>
                </p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">6.2.2.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">不得对</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">软件及相关服务的源网页进行任何形式的任何改动，包括但不限于</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">软件及相关服务的首页（</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">profile</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">页面）链接，广告系统链接等入口，也不得对</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">软件及相关服务的源页面的展示进行任何形式的遮挡、插入、弹窗等妨碍；</span>
                </p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">6.2.3.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">应当采取安全、有效、严密的措施，防止</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">软件及相关服务的信息内容被第三方通过包括但不限于</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">蜘蛛</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">（</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">spider</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">）程序等任何形式进行非法获取；</span></p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">6.2.4.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">不得把相关数据内容用于公司书面许可范围之外的目的，进行任何形式的销售和商业使用，或向第三方泄露、提供或允许第三方为任何方式的使用。</span>
                </p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">6.2.5.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">用户向任何第三人分享、转发、复制</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">软件及相关服务信息内容的行为，还应遵守公司为此制定的其他规范和标准。</span>
                </p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">7.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">违约处理</span></p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">7.1.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">针对您违反本协议或其他服务条款的行为，公司有权独立判断并视情况采取预先警示、拒绝发布、立即停止传输信息、删除跟帖、短期禁止发言、限制帐号部分或者全部功能直至永久关闭帐号等措施。公司有权公告处理结果，且有权根据实际情况决定是否恢复使用。对涉嫌违反法律法规、涉嫌违法犯罪的行为将保存有关记录，并依法向有关主管部门报告、配合有关主管部门调查。</span>
                </p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">7.2.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">因您违反本协议或其他服务条款规定，引起第三方投诉或诉讼索赔的，您应当自行承担全部法律责任。因您的违法或违约行为导致公司及其关联公司、控制公司向任何第三方赔偿或遭受国家机关处罚的，您还应足额赔偿公司及其关联公司、控制公司因此遭受的全部损失。</span>
                </p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">7.3.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">公司尊重并保护法人、公民的知识产权、名誉权、姓名权、隐私权等合法权益。您保证，在使用</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">软件及相关服务时上传的文字、图片、视频、音频、链接等不侵犯任何第三方的知识产权、名誉权、姓名权、隐私权等权利及合法权益。否则，公司有权在收到权利方或者相关方通知的情况下移除该涉嫌侵权内容。针对第三方提出的全部权利主张，您应自行承担全部法律责任；如因您的侵权行为导致公司及其关联公司、控制公司遭受损失的（包括经济、商誉等损失），您还应足额赔偿公司及其关联公司、控制公司遭受的全部损失。</span>
                </p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">8.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">服务的变更、中断和终止</span></p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">8.1</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">您理解并同意，公司提供的</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">软件及相关服务是按照现有技术和条件所能达到的现状提供的。公司会尽最大努力向您提供服务，确保服务的连贯性和安全性。您理解，公司不能随时预见和防范技术以及其他风险，包括但不限于不可抗力、病毒、木马、黑客攻击、系统不稳定、第三方服务瑕疵及其他各种安全问题的侵扰等原因可能导致的服务中断、数据丢失以及其他的损失和风险。</span>
                </p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">8.2</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">您理解并同意，公司为了服务整体运营的需要，有权在公告通知后修改、中断、中止或终止</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">软件及相关服务（包括收费网络服务），如变更、中断或终止的网络服务属于免费网络服务，</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">无需通知用户，也无需对任何用户或任何第三方承担任何责任；如变更、中断或终止的网络服务属于收费网络服务，</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">应当在变更、中断或终止之前事先通知用户，并应向您就受影响的服务部分提供等值的替代性的收费网络服务，如用户不愿意接受替代性的收费网络服务，就该用户已经向</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">支付的服务费，</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">应当按照该用户实际使用相应收费网络服务的情况扣除相应服务费之后将剩余的服务费退还给该用户。</span>
                </p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">9.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">广告</span></p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">9.1.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">您使用</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">软件及相关服务过程中，充分理解并同意：本服务中可能包括公司针对个人或企业推出的信息、广告发布或品牌推广服务，您同意公司有权在</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">软件及相关服务中展示</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">软件及相关服务相关和</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">/</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">或第三方供应商、合作伙伴的商业广告、推广或信息（包括商业或非商业信息）。</span>
                </p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">9.2.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">如您不同意该广告，您有权选择关闭该广告信息；</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">推送通知服务的，您有权自行关闭该服务或可停止使用</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">软件及相关服务。</span></p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">9.3.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">公司依照法律规定履行广告及推广相关义务，您应当自行判断该广告或推广信息的真实性和可靠性并为自己的判断行为负责。除法律法规明确规定外，您因该广告或推广信息进行的购买、交易或因前述内容遭受的损害或损失，用户应自行承担，公司不予承担责任。</span>
                </p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">10.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">知识产权</span></p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">10.1.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">公司在</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">软件及相关服务中提供的内容（包括但不限于软件、技术、程序、网页、文字、图片、图像、音频、视频、图表、版面设计、电子文档等）的知识产权属于公司所有。公司提供本服务时所依托的软件的著作权、专利权及其他知识产权均归公司所有。未经公司许可，任何人不得擅自使用（包括但不限于通过任何机器人、蜘蛛等程序或设备监视、复制、传播、展示、镜像、上载、下载</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">软件及相关服务中的内容）。</span></p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">10.2.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">您理解并同意，在使用</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">软件及相关服务时发布上传的文字、图片、视频、音频等均由您原创或已获合法授权。您通过</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">上传、发布的任何内容的知识产权归属您或原始著作权人所有。</span>
                </p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">10.3.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">您知悉、理解并同意您通过</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">发布上传的内容，授权公司及其关联公司、控制公司可在全球范围内、免费、非独家、可转授权地使用，使用范围包括但不限于在当前或其他网站、应用程序、产品或终端设备等，并授权公司及其关联公司、控制公司对相应内容可进行修改、复制、改编、翻译、汇编或制作衍生产品。</span>
                </p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">10.4.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">您确认并同意授权公司以公司自己的名义或委托专业第三方对侵犯您上传发布的享有知识产权的内容进行代维权，维权形式包括但不限于：监测侵权行为、发送维权函、提起诉讼或仲裁、调解、和解等，公司有权对维权事宜做出决策并独立实施。</span>
                </p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">10.5.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">公司为</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">开发、运营提供技术支持，并对</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">软件及相关服务的开发和运营等过程中产生的所有数据和信息等享有全部权利。</span>
                </p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">10.6.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">请您在任何情况下都不要私自使用公司的包括但不限于</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">等在内的任何商标、服务标记、商号、域名、网站名称或其他显著品牌特征等（以下统称为</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">标识</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">）。未经公司事先书面同意，您不得将本条款前述标识以单独或结合任何方式展示、使用或申请注册商标、进行域名注册等，也不得实施向他人明示或暗示有权展示、使用、或其他有权处理该些标识的行为。由于您违反本协议使用公司上述商标、标识等给公司或他人造成损失的，由您承担全部法律责任。</span>
                </p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">11.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">隐私政策</span></p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">11.1.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">详见《鹿老师说专项练习隐私政策》相关内容。</span></p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">12.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">免责声明</span></p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">12.1.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">您理解并同意，</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">软件及相关服务可能会受多种因素的影响或干扰，公司不保证</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">(</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">包括但不限于</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">)</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">：</span></p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">12.1.1.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">公司完全适合用户的使用要求；</span></p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">12.1.2.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">公司不受干扰，及时、安全、可靠或不出现错误；用户经由公司取得的任何软件、服务或其他材料符合用户的期望；</span>
                </p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">12.1.3.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">软件中任何错误都将能得到更正。</span></p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">12.2.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">对于涉嫌借款或其他涉财产的网络信息、账户密码、广告或推广等信息的，用户请谨慎对待并自行进行判断，基于前述原因您因此遭受的利润、商业信誉、资料损失或其他有形或无形损失，公司不承担任何直接、间接、附带、特别、衍生性或惩罚性的赔偿责任。</span>
                </p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">12.3.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">用户理解并同意，在使用</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">软件及相关服务过程中，可能遇到不可抗力等因素（不可抗力是指不能预见、不能克服并不能避免的客观事件），包括但不限于政府行为、自然灾害、网络原因、黑客攻击、战争或任何其它类似事件。出现不可抗力情况时，公司将努力在第一时间及时修复，但因不可抗力给用户造成了损失，用户同意公司不承担责任。</span>
                </p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">12.4.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">公司依据本协议约定获得处理违法违规内容的权利，该权利不构成公司的义务或承诺，公司不能保证及时发现违法行为或进行相应处理。</span>
                </p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">12.5.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">用户阅读、理解并同意：关于本协议服务，公司不提供任何种类的明示或暗示担保或条件，包括但不限于商业适售性、特定用途适用性等。您对本协议软件及相关服务的使用行为必须自行承担相应风险。</span>
                </p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">12.6.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">用户阅读、理解并同意，本协议是在保障遵守国家法律法规、维护公序良俗，保护他人合法权益，公司在能力范围内尽最大的努力按照相关法律法规进行判断，但并不保证公司判断完全与司法机关、行政机关的判断一致，如因此产生的后果用户已经理解并同意自行承担。</span>
                </p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">13.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">关于单项服务的特殊约定</span></p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">13.1.“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">软件及相关服务中包含公司以各种合法方式获取的信息或信息内容链接，同时也包括公司及其关联公司、控制公司合法运营的其他单项服务。这些服务在</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">以单独板块形式存在，公司有权不时地增加、减少或改动这些特别板块的设置及服务。</span>
                </p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">13.2.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">您可以在</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">软件中开启和使用上述单项服务功能。某些单项服务可能需要您同时接受就该服务特别制订的协议或者其他约束您与该项服务提供者之间的规则。公司将以醒目的方式提供这些协议、规则供您查阅。一旦您开始使用上述服务，则视为您同时接受有关单项服务的相关协议、规则的约束。</span>
                </p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">13.3.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">如您可能在</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">软件中使用第三方提供的软件及相关服务时，除遵守本协议及本鹿老师说专项练习相关规则外，还应遵守第三方的协议、相关规则。如因第三方软件及相关服务产生的争议、损失或损害，由用户与第三方解决。</span>
                </p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">14.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">未成年人使用条款</span></p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">14.1.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">若您是未满</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">18</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">周岁的未成年人，在使用</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">软件及相关服务前，应在您的父母或其他监护人监护、指导下阅读并同意本协议并获得监护人同意情况下阅读本协议和使用</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">软件及相关服务。</span></p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">14.2.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">公司重视对未成年人个人信息的保护，未成年用户在填写个人信息时，请加强个人保护意识并谨慎对待，并在监护人指导时正确使用</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">软件及相关服务。公司只会在法律允许、父母或其他监护人明确同意或保护未成年人所必要的情况下收集、使用或公开披露未成年人的个人信息；如果我们发现在未事先获得可证实的监护人同意的情况下收集了未成年人的个人信息，将会设法尽快删除相关数据。</span>
                </p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">14.3.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">未成年用户理解如因您违反法律法规、本协议内容，则您及您的监护人应依照法律规定承担因此而导致的一切后果。</span>
                </p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">14.4.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">未成年人用户特别提示：</span></p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">14.4.1.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">青少年使用本软件及相关服务应该在其监护人的监督指导下，在合理范围内正确学习使用网络，避免沉迷虚拟的网络空间，养成良好上网习惯。</span>
                </p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">14.4.2.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">青少年用户必须遵守《全国青少年网络文明公约》：</span>
                </p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">要善于网上学习，不浏览不良信息；</span></p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">要诚实友好交流，不侮辱欺诈他人；</span></p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">要增强自护意识，不随意约会网友；</span></p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">要维护网络安全，不破坏网络秩序；</span></p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">要有益身心健康，不沉溺虚拟时空。</span></p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">14.5.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">为更好的保护未成年人隐私权益，公司提醒用户慎重发布包含未成年人素材的内容，一经发布，即视为用户同意本软件及相关服务展示未成年人的信息、肖像、声音等，且允许公司依据本协议使用、处理该等与未成年人相关的内容。</span>
                </p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">15.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">其他</span></p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">15.1.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">本协议的成立、生效、履行、解释及争议的解决均应适用中华人民共和国大陆地区法律。倘本协议之任何规定因与中华人民共和国大陆地区的法律抵触而无效，则这些条款将尽可能接近本协议原条文意旨重新解析，且本协议其它规定仍应具有完整的效力及效果。</span>
                </p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">15.2.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">本协议的签署地点为中华人民共和国北京市海淀区，若您与公司发生争议的，双方应尽量友好协商解决，协商不成，您同意应将争议提交至北京市海淀区法院管辖。</span>
                </p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">15.3.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">公司有权依据国家政策、技术条件、产品功能等变化需要而进行修改本协议，公司会将修改后的协议予以发布。前述内容一经正式发布，将以适当的方式送达用户（网站公布、系统通知等），提醒您更新的内容，以便您及时了解本协议的最新版本。修改后的内容将构成本协议不可分割的组成部分，您应同样遵守。您对修改后的协议有异议的，请立即停止登录、使用</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">软件及相关服务，若您登录或继续使用</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">软件及相关服务，视为认可修改后的协议。</span></p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">15.4.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">本协议中的标题仅为方便及阅读而设，并不影响本协议中任何规定的含义或解释。</span>
                </p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">15.5.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">您和公司均是独立的主体，在任何情况下本协议不构成公司对用户的任何形式的明示或暗示担保或条件，双方之间亦不构成代理、合伙、合营或雇佣关系。</span>
                </p>
                <p style="margin:0px;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0px;">15.6.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0px;">本协议的版权为公司所有，在法律允许的范围内，公司保留解释和修改的权利。</span>
                </p>
                <p style="margin:0px;margin-bottom:10px;text-align:justify;font-size:16px;font-family:Cambria,serif;">
                    <br></p>
            </div>
        </div>
    </div>
</template>

<script>
    import Constants from "../../utils/Constants";

    export default {
        data() {
            return {
                logo: Constants.landPagePic.logo
            }
        }
    }
</script>

<style lang="scss" scoped>
    .privacypolicy {
        a {
            word-break: break-word;
        }

        .logo {
            height: 21px;
            margin: auto;
            display: block;
            padding-top: 35px;
            padding-bottom: 70px;
        }

        .wrapContentExt {
            background-color: #ffffff;
            width: 590px;
            padding: 13px 70px;
            margin: 30px auto;
            box-shadow: 0px 0px 10px 3px #d3b3b3;
            margin-bottom: 100px;
            padding-bottom: 60px;

            &.phone {
                background-color: #ffffff;
                padding: 0 18px;
                padding-bottom: 60px;
            }
        }
    }

    @media screen and (max-width: 700px) {
        .privacypolicy {
            .logo {
            }

            .wrapContentExt {
                width: auto !important;
                box-shadow: none !important;
                background-color: #ffffff !important;
                padding: 0 18px !important;
                padding-bottom: 60px !important;
                margin: 0 !important;
            }
        }
    }
</style>